import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { makeRequest } from "../../axios";
import { useInfiniteQuery } from "@tanstack/react-query";
import Post from "../../components/post/Post";

import Loading from "../../components/loading/Loading";
import NoItems from "../../components/NoItems/NoItems";
import { useInView } from "react-intersection-observer";
import Load from "../../components/Load";
import ShareButton from "../../components/share/Share";

const Cat = () => {
  const cat = useLocation().pathname.split("/")[2];
  const googlesearch = useLocation().search.split("?")[1];

  const [search, setSearch] = useState(
    decodeURIComponent(decodeURIComponent(googlesearch || ""))
  );
  const keys = ["storename", "title", "location", "storetitle", "cat", "desc"];
  const encodedSearch = encodeURIComponent(encodeURIComponent(search));

  const content = {
    title: "Salfad Marketplace",
    text: "Check out amazing products on salfad.com!",
    url: `https://www.salfad.com/cat/${cat}/?${encodedSearch}`,
  };

  const fetchPosts = ({ pageParam = 0 }) =>
    makeRequest
      .get(`/posts/category/${cat}?limit=5&offset=${pageParam}`)
      .then((res) => res.data);

  const {
    data,
    isError,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["cat"],
    queryFn: fetchPosts,
    getNextPageParam: (lastPage, pages) => {
      return lastPage && lastPage.length ? pages.length * 5 : undefined;
    },
  });

  const allPosts = data ? data.pages.flat() : [];

  const { ref, inView } = useInView();

  const loadMorePosts = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    if (inView) {
      loadMorePosts();
    }
  }, [inView, loadMorePosts]);

  return (
    <div className="posts">
      <p
        className="row"
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <input
          className="form-control m-2 col-md-3"
          type="search"
          placeholder="Search products and stores form this category"
          aria-label="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {search.length > 0 && (
          <ShareButton
            title={content.title}
            text={content.text}
            url={content.url}
          />
        )}
      </p>

      {isError ? (
        "Something went wrong"
      ) : isLoading && !allPosts.length ? (
        <Loading />
      ) : !allPosts.length ? (
        <NoItems text={"No Items found in this category"} cat={cat} />
      ) : (
        <>
          {allPosts
            .filter((post) =>
              keys.some((key) =>
                post[key]?.toLowerCase().includes(search.toLowerCase())
              )
            )
            .map((post) => (
              <Post post={post} key={post.id} />
            ))}

          <div
            ref={ref}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              width: "100%",
            }}
          >
            {isFetchingNextPage && (
              <div style={{ position: "fixed", bottom: "0px" }}>
                <Load />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Cat;
