import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeRequest } from "../../axios";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
// import PaymentButton from "../../components/payment/Payment";
import { AuthContext } from "../../context/authContext";
import Loading from "../../components/loading/Loading";

const Promote = () => {
  const state = useLocation().state;

  const [count, setCount] = useState(1);
  const [price, setPrice] = useState(7500);
  const [err, setErr] = useState(null);
  const [isLoading, setLoad] = useState(false);

  const postId = useLocation().pathname.split("/")[2];

  const { currentUser } = useContext(AuthContext);

  const id = currentUser?.id;
  const [lowCoin, setlowCoin] = useState(false);
  const [needCoins, setneedCoins] = useState(0);
  const availcoin = state?.coins || 0;

  const navigate = useNavigate();
  // if (!currentUser) {
  //   return <Navigate to="/login" />;
  // }
  useEffect(() => {
    if (count < 1) {
      setCount(1);
    } else {
      setPrice(5);
    }
  }, [count]);

  // const increment = () => {
  //   setCount(count + 1);
  // };

  // const decrement = () => {
  //   setCount(count - 1);
  // };

  const finalPrice = count * price;

  // const formattedPrice2 = new Intl.NumberFormat("en-NG", {
  //   style: "currency",
  //   currency: "NGN",
  // }).format(finalPrice);

  // const formattedPrice = new Intl.NumberFormat("en-NG", {
  //   style: "currency",
  //   currency: "NGN",
  // }).format(price);

  const handleNoCoins = (e) => {
    setlowCoin(true);

    const coin = availcoin - finalPrice;
    setneedCoins(coin * -1);
  };

  const handlePromte = async (e) => {
    try {
      setLoad(true);
      await makeRequest.post(`/verify/${postId}`, {
        count,
        id: id,
        coins: finalPrice,
      });
      navigate(`/item/${postId}`, {
        state: { fromm: "Product has been Promoted" },
      });
    } catch (err) {
      setErr(err);
    }
  };

  console.log("id" + postId);

  console.log(err);
  if (!currentUser) {
    return <Navigate to="/login" />;
  }
  if (!state) {
    return <Navigate to={`/item/${postId}`} />;
  }
  return (
    <div className="container mt-5">
      <div className="text-center mb-4">
        <h1 className="display-4">
          <b>Promote Product</b>
        </h1>
      </div>

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <div className="row justify-content-center">
          <div className="col-md-6 ">
            {lowCoin && (
              <div
                className="alert alert-danger alert-dismissible fade show text-capitalize text-center fixed-bottom"
                role="alert"
              >
                <p>
                  <h1>Not Enough Coins</h1>
                  <p>
                    You need {needCoins} more coins to carry out this command
                  </p>
                  <br />
                  <Link
                    to={"/buycoins"}
                    state={{ coins: needCoins }}
                    className="btn btn-primary text-capitalize"
                  >
                    Purchase More
                  </Link>
                </p>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setlowCoin(false)}
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            )}
            <div className="card">
              <div className="card-body justify-content-between">
                <p className="count-display ">
                  Number of Weeks:{" "}
                  <span className="badge bg-primary">{count}</span>
                </p>
                <p className="count-display">
                  Price Per Week: <span className="badge bg-info">{price}</span>
                </p>
                <p className="count-display">
                  Total Price:{" "}
                  <span className="badge bg-success">{finalPrice}</span>
                </p>
              </div>
              <div className="card-footer d-flex justify-content-between">
                {/* <button className="btn btn-sm btn-danger" onClick={decrement}>
                  Decrement
                </button> */}
                {/* <PaymentButton
                  runSaveHandleItemPurchased={handlePromte}
                  finalPrice={finalPrice}
                /> */}
                <input
                  placeholder="Weeks for Promotion (e.g., 10)"
                  type="number"
                  className="form-control text-center"
                  inputMode="numeric"
                  onChange={(e) => setCount(e.target.value)}
                />

                {availcoin >= finalPrice ? (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={handlePromte}
                  >
                    Promote
                  </button>
                ) : (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={handleNoCoins}
                  >
                    Promote
                  </button>
                )}
                {/* <button className="btn btn-sm btn-success" onClick={increment}>
                  Increment
                </button> */}
              </div>
              <p className="text-center text-success text-capitalize">
                Promoting your product adds a gold band for trust and features
                it in top ads.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Promote;
