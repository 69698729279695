import { useInfiniteQuery } from "@tanstack/react-query";
import { useContext, useState, useEffect, useCallback } from "react";
import { AuthContext } from "../../context/authContext";
import { makeRequest } from "../../axios";

import Post from "../../components/post/Post";
import Loading from "../../components/loading/Loading";
import NoItems from "../../components/NoItems/NoItems";
import { useInView } from "react-intersection-observer";
import "./home.scss";
import Load from "../../components/Load";
import { useLocation } from "react-router-dom";
import ShareButton from "../../components/share/Share";
import CatCar from "../../components/cat-car/CatCar";

const Home = () => {
  const { currentUser } = useContext(AuthContext);
  const googlesearch = useLocation().search.split("?")[1];

  const [search, setSearch] = useState(
    decodeURIComponent(decodeURIComponent(googlesearch || ""))
  );
  const keys = ["storename", "title", "location", "storetitle", "cat", "desc"];
  const state = useLocation().state;
  const encodedSearch = encodeURIComponent(encodeURIComponent(search));

  const content = {
    title: "Salfad Marketplace",
    text: "Check out amazing products on salfad.com!",
    url: `https://www.salfad.com/home/?${encodedSearch}`,
  };

  const fetchPosts = ({ pageParam = 0 }) =>
    makeRequest
      .get(`/posts?limit=5&offset=${pageParam}`)
      .then((res) => res.data);

  const {
    data,
    isError,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["posts"],
    queryFn: fetchPosts,
    getNextPageParam: (lastPage, pages) => {
      return lastPage && lastPage.length ? pages.length * 5 : undefined;
    },
  });

  const allPosts = data ? data.pages.flat() : [];

  const { ref, inView } = useInView();

  const loadMorePosts = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    if (inView) {
      loadMorePosts();
    }
  }, [inView, loadMorePosts]);

  return (
    <div className="posts">
      <CatCar />
      <p
        className="row"
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <input
          className="form-control m-2 col-md-3"
          type="search"
          placeholder="Search products, stores and categories"
          aria-label="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {search.length > 0 && (
          <ShareButton
            title={content.title}
            text={content.text}
            url={content.url}
          />
        )}
      </p>

      {isError ? (
        "Something went wrong"
      ) : isLoading && !allPosts.length ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Loading />
        </div>
      ) : !allPosts.length ? (
        <NoItems text={"No Items found"} />
      ) : (
        <>
          {state && (
            <div
              className="alert alert-success alert-dismissible fade show text-capitalize text-center fixed-bottom  small "
              role="alert"
            >
              {state.fromm} <br />{" "}
              <a href=" https://chat.whatsapp.com/LNtcY5HmhdvG25SbVRWTau ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                </svg>{" "}
              </a>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          )}
          {allPosts
            .filter((post) =>
              keys.some((key) =>
                post[key]?.toLowerCase().includes(search.toLowerCase())
              )
            )
            .map((post) => (
              <Post post={post} key={post.id} />
            ))}

          <div
            ref={ref}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              bottom: "0px",
              width: "100%",
            }}
          >
            {isFetchingNextPage && (
              <div>
                <Load />
              </div>
            )}
          </div>
        </>
      )}

      {currentUser && (
        <a style={{ textDecoration: "none", color: "black" }} href="/create">
          <button
            className="btn m-3 position-fixed bottom-0 end-0"
            style={{ borderRadius: "50%" }}
          >
            <img
              style={{ maxWidth: "60px", maxHeight: "60px" }}
              src="../../img/add.png"
              alt=""
            />
          </button>
        </a>
      )}
    </div>
  );
};

export default Home;
